<template>
  <div class="settings-index">
    <div class="mx-auto mb-8 text-center">
      <h1 class="mb-8 text-2xl font-black text-center">
        {{ $t("profile.your-profile") }}
      </h1>
    </div>

    <div class="max-w-md px-4 mx-auto space-y-8 text-center">
      <div class="flex flex-col mx-auto space-y-3">
        <router-link :to="{ name: 'settings-avatar' }">
          <list-item>
            <template v-slot:start>
              <span class="text-sm font-medium text-brand-lightgray">{{ $t("profile.avatar") }}</span>
            </template>

            <template v-slot:default>
              <div class="relative block w-full h-6" v-if="getAvatar">
                <img :src="require(`assets/avatars/avatar_${getAvatar}.png`)"
                  class="absolute right-0 h-6 overflow-hidden rounded-full" />
              </div>
            </template>
          </list-item>
        </router-link>

        <template>
          <router-link :to="{ name: 'journal-index' }">
            <list-item>
              <template v-slot:start>
                <span class="text-sm font-medium text-brand-lightgray">{{ $t("navigation.journal") }}</span>
              </template>
            </list-item>
          </router-link>

          <router-link :to="{ name: 'info-checklist' }">
            <list-item>
              <template v-slot:start>
                <span class="text-sm font-medium text-brand-lightgray">{{ $t("navigation.checklist") }}</span>
              </template>
            </list-item>
          </router-link>
        </template>

        <list-item @click.native="namePopupOpen = true">
          <template v-slot:start>
            <span class="text-sm font-medium text-brand-lightgray">{{ $t("profile.name") }}</span>
          </template>

          <template v-slot:default>
            <div class="flex items-center justify-end truncate">
              <template>
                <span class="text-sm font-semibold truncate" v-if="!!username">{{ username }}</span>

                <span class="text-sm italic text-gray-600" v-else>{{ $t('profile.name-not-set') }}</span>
              </template>
            </div>
          </template>
        </list-item>

        <router-link :to="{ name: 'profile' }" class="opacity-75">
          <list-item>
            <template v-slot:start>
              <span class="text-sm font-medium text-brand-lightgray">{{ $t("profile.password") }}</span>
            </template>

            <template v-slot:default>
              <div class="flex items-center justify-end font-medium tracking-wider 2xs:tracking-widest">
                <span class="hidden 2xs:inline">**</span>******
              </div>
            </template>
          </list-item>
        </router-link>

        <router-link :to="{ name: 'profile' }" class="opacity-75">
          <list-item>
            <template v-slot:start>
              <span class="text-sm font-medium text-brand-lightgray">{{ $t("profile.email") }}</span>
            </template>

            <template v-slot:default>
              <div class="flex items-center justify-end truncate">
                <span class="text-sm font-semibold truncate">{{ profile.email || "unknown" }}</span>
              </div>
            </template>
          </list-item>
        </router-link>

        <list-item @click.native="safetyBuddyOpen = true">
          <template v-slot:start>
            <span class="text-sm font-medium text-brand-lightgray">{{ $t("profile.safety-buddy") }}</span>
          </template>

          <template v-slot:default>
            <div class="flex justify-end text-xs font-medium opacity-25">
              {{ buddies.map((b) => b.name).join(", ") }}
            </div>
          </template>
        </list-item>

        <list-item>
          <template v-slot:start>
            <span class="text-sm font-medium text-brand-lightgray">{{ $t("profile.locale") }}</span>
          </template>

          <template v-slot:end>
            <locale-switcher class="self-end font-medium" />
          </template>
        </list-item>

        <list-item @click.native="notificationPreferencesPopupOpen = true">
          <template v-slot:start>
            <span class="text-sm font-medium text-brand-lightgray">{{ $t("profile.preferences-notifications.title")
            }}</span>
          </template>
        </list-item>

        <router-link :to="{ name: 'partners' }">
          <list-item>
            <template v-slot:start>
              <span class="text-sm font-medium text-brand-lightgray">{{ $t("pages.partners") }}</span>
            </template>
          </list-item>
        </router-link>

        <list-item>
          <template v-slot:start>
            <div class="flex items-center justify-between">
              <label for="analytics" class="text-sm font-medium text-brand-lightgray">{{ $t("profile.tag_manager")
              }}</label>
            </div>
          </template>

          <template v-slot:end>
            <t-checkbox v-model="enableAnalytics" id="analytics"></t-checkbox>
          </template>
        </list-item>

        <router-link :to="{ name: 'tutorial-index' }">
          <list-item>
            <template v-slot:start>
              <span class="text-sm font-medium text-brand-lightgray">{{ $t("pages.tutorial.title") }}</span>
            </template>
          </list-item>
        </router-link>

        <p class="text-sm maw-w-xs">
          <router-link variant="link" is="t-button" :to="{ name: 'policy' }">{{ $t("pages.policy") }}</router-link>
        </p>
      </div>

      <t-button variant="danger" class="w-full rounded-full" @click="logout">
        {{ $t("auth.logout") }}
      </t-button>

      <p class="text-red-400 underline" @click="onDeleteAccountClick">
        {{ $t("pages.settings.delete_account") }}
      </p>
    </div>

    <add-safety-buddy @close="safetyBuddyOpen = false" v-if="safetyBuddyOpen" :show-buddies="true" />

    <popup-pane @close="namePopupOpen = false" v-if="namePopupOpen" :title="$t('profile.change_name')"
      :dark="isCheckedIn">
      <edit-name @close="namePopupOpen = false" />
    </popup-pane>

    <popup-pane @close="notificationPreferencesPopupOpen = false" v-if="notificationPreferencesPopupOpen"
      :title="$t('profile.preferences-notifications.title')" :dark="isCheckedIn">
      <p class="mb-2 text-base">{{ $t("profile.preferences-notifications.change") }}:</p>
      <notification-preferences />
    </popup-pane>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { AUTH_LOGOUT } from "@/store/actions/auth";
import { UPDATE_ANALYTICS } from '@/store/actions/user';

import ListItem from "@/components/ListItem";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import PopupPane from "@/components/PopupPane";
import AddSafetyBuddy from "@/components/Settings/AddSafetyBuddy";
import EditName from "@/components/Settings/EditName";
import NotificationPreferences from "@/components/Dashboard/NotificationPreferences";

import UserRepository from "@/repositories/UserRepository";

export default {
  name: "MobileSettings",
  components: {
    EditName,
    PopupPane,
    AddSafetyBuddy,
    ListItem,
    LocaleSwitcher,
    NotificationPreferences
  },

  data() {
    return {
      safetyBuddyOpen: false,
      namePopupOpen: false,
      phonePopupOpen: false,
      notificationPreferencesPopupOpen: false,
    };
  },

  computed: {
    ...mapGetters({
      profile: "getProfile",
      username: "getUsername",
      getAvatar: "getAvatar",
      buddies: "getBuddies",
      isCheckedIn: "isCheckedIn",
    }),

    enableAnalytics: {
      get() {
        return this.profile.enable_analytics;
      },
      set(value) {
        this.$store.commit(UPDATE_ANALYTICS, value);
      },
    },
  },

  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push({ name: "login" }));

      this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;
      this.$moment.locale(process.env.VUE_APP_I18N_LOCALE);
    },

    async onDeleteAccountClick() {
      try {
        const confirmDelete = confirm(this.$t("pages.settings.confirm_delete_account"));

        if (!confirmDelete) return;

        await UserRepository.delete();
        this.logout();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
