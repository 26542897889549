<template>
  <div class="settings-index">
    <mobile-settings class="md:hidden" />
    <desktop-settings class="hidden md:block" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DesktopSettings from "../../layouts/profile/Desktop";
import MobileSettings from "../../layouts/profile/Mobile";

export default {
  name: "settings-index",

  components: { DesktopSettings, MobileSettings },

  computed: {
    ...mapGetters({ profile: "getProfile" }),
  },

  metaInfo() {
    return {
      title: `${this.profile.name || "Loading..."} - ${this.$i18n.t(
        "profile.your-profile"
      )}`,
    };
  },
};
</script>
