<template>
  <form
    @submit.prevent="updateNameForm"
    @keydown="form.onKeydown($event)"
    name="login"
    class="flex flex-col max-w-xl p-4 mx-auto space-y-4"
    aria-autocomplete="off"
    autocomplete="off"
  >
    <t-input-group :label="$t('profile.name')">
      <t-input v-model="form.name" placeholder="Your name" />
    </t-input-group>

    <t-button type="submit" :disabled="form_loading || !hasChanged">{{
      form_loading ? $t("action.saving") : $t("action.save")
    }}</t-button>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_REQUEST } from "actions/user";
import Repository from "repositories/RepositoryFactory";
import { Form } from "vform";
const UserRepository = Repository.get("user");

export default {
  name: "EditName",

  data: () => ({
    form: new Form({
      identifier: null,
      name: null,
      phone: null
    }),
    form_loading: false,
    form_error: ""
  }),

  computed: {
    ...mapGetters(["getUsername"]),
    hasChanged() {
      return this.form.name !== this.getUsername;
    }
  },

  methods: {
    updateNameForm() {
      this.form_loading = true;
      const { name } = this.form;
      UserRepository.updateName(name)
        .then(() => {
          this.form.clear();
          this.$emit("close");
        })
        .catch(err => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.formErrors = ["Failed to update buddy."];
        })
        .then(() => {
          this.form_loading = false;
          this.$store.dispatch(USER_REQUEST);
        });
    }
  },

  created() {
    this.$store.dispatch(USER_REQUEST);
    this.form.fill({ name: this.getUsername });
  }
};
</script>

<style scoped></style>
