<template>
  <div class="w-full max-w-lg mx-24">
    <div class="space-y-12">

      <div class="flex flex-row items-start justify-between gap-2">
        <div class="flex flex-col">
          <h1 class="mb-2 text-3xl font-light">{{ $t("pages.settings.title") }}</h1>

          <h2 class="text-2xl font-bold">{{ $t("pages.settings.my_profile") }}</h2>
        </div>
        <t-button class="font-bold" variant="simple" type="button" @click="logout">{{ $t("auth.logout") }}
        </t-button>
      </div>

      <form @submit.prevent="onSubmit" id="profile-desktop" class="flex flex-col gap-4 mt-4 mb-6" autocomplete="off">
        <t-input-group label="Avatar">
          <div class="flex items-center">
            <transition name="fade" mode="out-in">
              <avatar v-if="!avatarPickerOpen" class="w-20 h-20 mr-12 border-2 border-white rounded-full" />

              <avatar-selector v-else class="w-full" :avatar="getAvatar" @avatar-changed="onAvatarChange">
              </avatar-selector>
            </transition>

            <div class="w-full">
              <t-button class="font-bold" type="button" @click="onAvatarChangeClick">{{
                $t(`${avatarPickerOpen ? "action.save" : "profile.btn--update-avatar"}`)
                }}</t-button>
            </div>
          </div>
        </t-input-group>

        <div class="flex items-start justify-between space-x-4">
          <t-input-group :label="$t('profile.name')" class="w-1/2">
            <t-input type="text" v-model="form.name" autocomplete="username" />
          </t-input-group>

          <t-input-group :label="$t('profile.password')" class="w-1/2">
            <t-input type="password" placeholder="*************" v-model="form.password"
              autocomplete="current-password" />
          </t-input-group>
        </div>

        <div class="">
          <t-input-group :label="$t('profile.email')" class="" :feedback="$t('profile.fill_password')"
            autocomplete="email">
            <t-input type="email" v-model="form.email" />
          </t-input-group>
        </div>


        <div class="max-w-full w-80">
          <t-button form="profile-desktop" type="submit">{{ $t("action.save") }}</t-button>
        </div>
      </form>


      <div class="flex flex-col w-full space-y-12">

        <div class="flex flex-col space-y-2">
          <h2 class="text-2xl font-bold">{{ $t("profile.locale") }}</h2>
          <locale-switcher class="w-64"></locale-switcher>
        </div>


        <div class="flex flex-col space-y-2">
          <h2 class="text-2xl font-bold">{{ $t("profile.your-safety-buddies") }}</h2>
          <p class="text-sm">{{ $t('profile.safety-buddies--intro') }}</p>
          <profile-buddies />
        </div>


        <div class="flex flex-col space-y-2">
          <h2 class="text-2xl font-bold">{{ $t("profile.preferences-notifications.title") }}</h2>
          <div class="text-sm">{{ $t("profile.preferences-notifications.change") }}</div>
          <notification-preferences class="w-full p-4 bg-white shadow rounded-xl text-brand-dark" />
        </div>

        <div class="flex flex-col space-y-2">
          <h2 class="text-xl font-bold">{{ $t("profile.tag_manager") }}</h2>
          <!-- <div class="text-sm">{{ $t("profile.tag_manager--intro") }}</div> -->

          <label class="flex items-center w-full p-4 bg-white shadow rounded-xl text-brand-dark">
            <t-checkbox v-model="enableAnalytics" />
            <span class="ml-2 text-sm">{{ $t("shared.enable_analytics") }}</span>
          </label>
        </div>

      </div>


      <div class="flex flex-col space-y-2">

        <h2 class="font-bold">{{ $t("profile.quicklinks") }}</h2>

        <div class="flex flex-col w-full space-y-4">
          <router-link :to="{ name: 'partners' }">
            <list-item>
              <template v-slot:start>
                <span class="text-sm font-medium text-brand-lightgray">{{ $t("pages.partners") }}</span>
              </template>
            </list-item>
          </router-link>

          <router-link :to="{ name: 'tutorial-index' }">
            <list-item>
              <template v-slot:start>
                <span class="text-sm font-medium text-brand-lightgray">{{ $t("pages.tutorial.title") }}</span>
              </template>
            </list-item>
          </router-link>


          <router-link :to="{ name: 'policy' }">
            <list-item>
              <template v-slot:start>
                <span class="text-sm font-medium text-brand-lightgray">{{ $t("pages.policy") }}</span>
              </template>
            </list-item>
          </router-link>

        </div>

      </div>

    </div>

    <div class="flex flex-col w-full mt-32">
      <div class="p-6 text-center bg-red-100 bg-opacity-50 border border-red-300 rounded-lg">

        <button class="font-semibold text-red-500 underline" @click="deleteAccount">
          {{ $t("pages.settings.delete_account") }}
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST, UPDATE_ANALYTICS } from "@/store/actions/user";

import Avatar from "@/components/Shared/Avatar.vue";
import AvatarSelector from "@/components/AvatarSelector";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import ProfileBuddies from "@/components/Profile/Buddies";
import NotificationPreferences from "@/components/Dashboard/NotificationPreferences";
import ListItem from "@/components/ListItem";

import UserRepository from "@/repositories/UserRepository";

export default {
  name: "DesktopSettings",

  components: {
    AvatarSelector,
    Avatar,
    LocaleSwitcher,
    ProfileBuddies,
    NotificationPreferences,
    ListItem
  },

  mounted() {
    this.form.name = this.getProfile.name;
    this.form.email = this.getProfile.email;
  },

  data: () => ({
    form: {
      name: "",
      email: "",
      password: "",
    },

    avatarPickerOpen: false,
    avatar: null,
  }),

  methods: {
    onSubmit() {
      if (this.form.name !== this.getProfile.name) {
        UserRepository.updateName(this.form.name).then(() => {
          this.$store.dispatch(USER_REQUEST);
        });
      }

      if (this.form.email !== this.getProfile.email) {
        UserRepository.updateEmail(this.form.email, this.form.password).then(
          () => {
            this.$store.dispatch(USER_REQUEST);
          }
        );
      }
    },

    onAvatarChangeClick() {
      this.avatarPickerOpen = !this.avatarPickerOpen;

      if (this.avatar !== this.getAvatar) {
        UserRepository.updateAvatar(this.avatar).then(() => {
          this.$store.dispatch(USER_REQUEST);
        });
      }
    },

    onAvatarChange(avatar) {
      this.avatar = avatar;
      console.log(this.getAvatar, avatar);
    },

    logout() {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => this.$router.push({ name: "login" }));

      this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;
      this.$moment.locale(process.env.VUE_APP_I18N_LOCALE);
    },

    async deleteAccount() {
      try {
        const confirmDelete = confirm(
          this.$t("pages.settings.confirm_delete_account")
        );

        if (!confirmDelete) return;

        await UserRepository.delete();
        this.logout();
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters(["getProfile", "getAvatar"]),


    enableAnalytics: {
      get() {
        return this.getProfile.enable_analytics;
      },
      set(value) {
        this.$store.commit(UPDATE_ANALYTICS, value);
      },
    },
  },
};
</script>

<style scoped></style>
