<template>
  <div class="flex flex-col w-full space-y-4">
    <div v-if="getBuddies.length" class="flex flex-col space-y-2">
      <safety-buddy-item v-for="buddy in getBuddies" :key="buddy.identifier" :buddy="buddy" />
    </div>
    <div class="p-4 text-center" v-else>
      <icon-buddy class="w-12 mx-auto text-brand-dark" />
      <p>{{ $t("profile.buddies.empty") }}</p>
    </div>

    <t-button @click="safetyBuddyOpen = true" :disabled="getBuddies.length >= 10">
      {{ $t("profile.btn--add-buddy") }}
    </t-button>

    <add-safety-buddy @close="safetyBuddyOpen = false" v-if="safetyBuddyOpen" :show-buddies="false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddSafetyBuddy from "@/components/Settings/AddSafetyBuddy";
import SafetyBuddyItem from "@/components/Settings/SafetyBuddyItem";
import IconBuddy from "assets/icons/buddy.svg";

export default {
  name: "Buddies",

  components: {
    SafetyBuddyItem,
    AddSafetyBuddy,
    IconBuddy
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      safetyBuddyOpen: false,
    };
  },

  methods: {
    init() {
      // console.log("hoi");
    },
  },

  computed: {
    ...mapGetters(["getBuddies"]),
  },
};
</script>

<style scoped>
</style>
