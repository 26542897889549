<template>
  <form class="flex flex-col space-y-1" v-on:submit.prevent="submit">
    <label class="flex items-center py-1" v-for="field in Object.keys(modalSettings)" :key="`notifPref_${field}`">
      <t-checkbox :name="`modalSettings[${field}]`" v-model="modalSettings[field]"
        @change.passive="onCheckboxChange($event, field)" />
      <span class="ml-2 text-sm">{{ $t(`profile.preferences-notifications.${field}`) }}</span>
    </label>
  </form>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";

import { UPDATE_MODAL_PREFERENCES } from '@/store/actions/user';

export default {
  name: "NotificationPreferences",

  mounted() {
    this.loadSettings();
  },

  data() {
    return {
      modalSettings: {},
    };
  },

  methods: {
    loadSettings(){
      this.modalSettings = { ...this.modalPreferences };
    },

    onCheckboxChange() {
      this.submit();
    },

    submit: debounce(function () {
      try {
        this.$store.commit(UPDATE_MODAL_PREFERENCES, this.modalSettings);
      } catch (e) {
        console.log(e);
      }
    }, 1000),
  },

  watch: {
    modalPreferences: function(){
      this.loadSettings();
    }
  },

  computed: {
    ...mapGetters(["modalPreferences"]),
  },
};
</script>